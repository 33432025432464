import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import client from '../../sanity/client';
import './SubPage.scss'
import { gsap } from 'gsap';

const SubPageBols = () => {
  const [data, setData] = useState(null);
  const [bols, setBols] = useState([]);
  const [isAnimating, setIsAnimating] = useState(true); // Nouvel état pour gérer la transition d'entrée

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "subPageBols"]{
        title,
        "imageUrl": image.asset->url,
        slug
      }`;
      
      const result = await client.fetch(query);
      setBols(result);
      setData(result);
      setIsAnimating(false); // Transition d'entrée terminée après le chargement des données
    };

    fetchData();
  }, []);

  // if (!bols || bols.length === 0) return <div>Loading...</div>;
  useEffect(() => {
    if (!isAnimating && data) {
      // Lancer une animation GSAP après le chargement des données
      gsap.fromTo(
        '.gallery',
        { opacity: 0.2 },
        { opacity: 1, duration: 1.2, ease: 'power1.out' }
      );
    }
  }, [isAnimating, data]);

  if (isAnimating) {
    // Afficher "Loading..." tout en masquant la page pendant le chargement des données
    return <div style={{ opacity: 0 }}>Loading...</div>;
  }

  return (
    <div className="sub-page">
      <div className="gallery">
        {bols.map((bol) => (
          bol.slug?.current ? (
            <div key={bol.slug.current} className="item">
              <Link to={`/bols/bols/${bol.slug.current}`}>
                <img src={bol.imageUrl} alt={bol.title} />
              </Link>
            </div>
          ) : null // Ignorer les bols sans slug
        ))}
      </div>
    </div>
  );
};

export default SubPageBols;