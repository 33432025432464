// src/components/IntroPage.js
import React, { useEffect, useState } from 'react';
import './IntroPage.scss';

import photoIntro from '../../assets/images/photoIntro.jpg'

const IntroPage = ({ onAnimationEnd }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onAnimationEnd, 2000); // Assure la fin de l'animation avant de cacher l'intro
    }, 300); // Le temps avant que l'animation commence

    return () => clearTimeout(timer);
  }, [onAnimationEnd]);

  return (
    <div className={`intro-page ${isVisible ? 'visible' : 'hidden'}`}>
        <div className="photoIntro_content">
            <img className="photoIntro" src={photoIntro} alt="" />
        </div>
        <h1>claire landais sauvage</h1>
    </div>
  );
};

export default IntroPage;