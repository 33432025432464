import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from '../../sanity/client';
import '../Ecriture/SubPageEcriture.scss'
import { useLanguage } from '../../components/LanguageContext'; // Importer le contexte

const SubPageEcriture = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const { language } = useLanguage(); // Récupérer la langue actuelle

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "subPageEcriture" && slug.current == $slug][0]{
        title,
        "imageUrl": image.asset->url,
        content
      }`;
      const params = { slug };
      const result = await client.fetch(query, params);
      setData(result);
    };

    fetchData();
  }, [slug]);

  if (!data) return <div>Loading...</div>;

  return (
    <div className="sub-page-left">
      {data.imageUrl && (
        <div className="spl_right_content">
          <img className="right_img" src={data.imageUrl} alt={data.title} />
        </div>
      )}
      <div className="spl_left_content">
        <p className="left_text">
          {data.content[language] ? data.content[language].split('\n').map((line, idx) => (
          <span className="lef_text_split" key={idx}>
            {line}
            <br />
          </span>
        )) : null}
        </p>
      </div>
    </div>
  );
};

export default SubPageEcriture;