import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';

const PageTransition = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Animation d'entrée
    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.page-container',
        { opacity: 0 },
        { opacity: 1, duration: 1.2, ease: 'power1.out' }
      );
    });

    return () => {
      // Animation de sortie
      const transition = gsap.timeline();
      transition.to('.page-container', {
        opacity: 0,
        duration: 1,
        ease: 'power1.inOut',
        onComplete: () => {
          // Laisser React gérer la transition après l'animation
          ctx.revert();
        },
      });
    };
  }, [location.pathname]);

  return <div className="page-container">{children}</div>;
};

export default PageTransition;