import React, { useEffect, useState, useRef } from 'react';
import client from '../../sanity/client';
import './Rencontres.scss';
import { useLanguage } from '../../components/LanguageContext'; // Importer le contexte
import { gsap } from 'gsap';

const RencontresPage = () => {
  const [data, setData] = useState(null);
  const { language } = useLanguage(); // Récupérer la langue actuelle
  const [isAnimating, setIsAnimating] = useState(true); // Nouvel état pour gérer la transition d'entrée

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "rencontres"][0]{
        pageTitle,
        works[]{
          title,
          description,
          "imageUrl": image.asset->url
        }
      }`;
      
      const result = await client.fetch(query);
      setData(result);
      setIsAnimating(false); // Transition d'entrée terminée après le chargement des données

    };

    fetchData();

  }, []);

  useEffect(() => {
    if (!isAnimating && data) {
      // Lancer une animation GSAP après le chargement des données
      gsap.fromTo(
        '.rencontres-page',
        { opacity: 0 },
        { opacity: 1, duration: 0, ease: 'power1.out' }
      );
    }
  }, [isAnimating, data]);

  if (isAnimating) {
    // Afficher "Loading..." tout en masquant la page pendant le chargement des données
    return <div style={{ opacity: 0 }}>Loading...</div>;
  }
  return (
    <div className="rencontres-page">
        <div className="rencontres-title">
            <h1>
                {data.pageTitle ? data.pageTitle.split('\n').map((line, idx) => (
                <span className="work-content-text-split" key={idx}>
                    {line}
                    <br />
                </span>
                )) : null}
            </h1> {/* Utilisation du titre principal de la page */}
        </div>
      <div className="works-list">
        {data.works && data.works.map((work, index) => (
          <div key={index} className="work-item">
            <div className="work-text">
              <h2 className="work-title">{work.title}</h2> {/* Titre pour chaque item */}
              <p className="work-content-text">
                 {work.description[language] ? work.description[language].split('\n').map((line, idx) => (
                  <span className="work-content-text-split" key={idx}>
                    {line}
                    <br />
                  </span>
                )) : null}
                </p>
            </div>
            <div className="work-image">
                {work.imageUrl && ( // Affichage de l'image si elle existe
                <img 
                    src={work.imageUrl} 
                    alt={`work-${index}`} 
                    className={`image-item image-item-${index}`}
                />
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RencontresPage;