import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import client from '../sanity/client';
import '../components/SidebarMenu.scss';
import HamburgerButton from '../components/HamburgerButton';
import { useLanguage } from '../components/LanguageContext';

const SidebarMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { language, switchLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [subMenuItems, setSubMenuItems] = useState({
    approche: [],
    bols: [],
    ecriture: [],
    transmettre: [],
  });

  // Ouverture et fermeture du menu
  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);
  const toggleSubMenu = (menuSlug) => {
    setActiveMenu(activeMenu === menuSlug ? null : menuSlug); // Basculer entre ouvrir/fermer le sous-menu
  };
  // Activer le menu en fonction du chemin de l'URL
  useEffect(() => {
    const path = location.pathname.split('/')[1];
    if (path) {
      setActiveMenu(path);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOpen]);

  const handleMenuClick = (menuSlug) => {
    setActiveMenu(menuSlug);
    navigate(`/${menuSlug}`);
    closeMenu(); // Ferme le menu après le clic
  };

  useEffect(() => {
    const fetchSubMenus = async () => {
      const query = `*[_type in ["subPageApproche", "subPageBols", "subPageEcriture", "subPageTransmettre"]]{
        _type,
        title,
        "slug": slug.current
      }`;
      const data = await client.fetch(query);
      setSubMenuItems({
        approche: data.filter((item) => item._type === 'subPageApproche') || [],
        bols: data.filter((item) => item._type === 'subPageBols') || [],
        ecriture: data.filter((item) => item._type === 'subPageEcriture') || [],
        transmettre: data.filter((item) => item._type === 'subPageTransmettre') || [],
      });
    };
    fetchSubMenus();
  }, []);

  return (
    <>
      <HamburgerButton isOpen={isOpen} toggleMenu={toggleMenu} />

    <nav className={`sidebar-menu ${isOpen ? 'open' : ''}`}>
      
      <ul>
        {/* Approche Menu */}
        <li>
          <a className={`menu_title ${location.pathname.includes('/approche') ? 'active italic' : ''}`}
            onClick={() => handleMenuClick('approche')}
          >
            approche
          </a>
          {activeMenu === 'approche' && (
            <ul className="sub-menu">
              {subMenuItems.approche.map((subItem) => (
                <li key={subItem.slug}>
                  <Link
                    to={`/approche/${subItem.slug}`}
                    className={location.pathname === `/approche/${subItem.slug}` ? 'italic' : ''}
                    onClick={closeMenu}
                  >
                    {subItem.title}
                  </Link>
                </li>
              ))}
              <li>
                <Link
                  to={`/approche/rencontres`}
                  className={location.pathname === '/approche/rencontres' ? 'italic' : ''}
                  onClick={closeMenu}
                >
                  rencontres
                </Link>
              </li>
            </ul>
          )}
        </li>
          {/* Autres menus */}
          <li>
            <a className={`menu_title ${location.pathname.includes('/bols') ? 'active italic' : ''}`}
              onClick={() => handleMenuClick('bols')}
            >
              bols
            </a>
            {activeMenu === 'bols' && (
              <ul className="sub-menu">
                <li>
                  <Link 
                  to={`/bols/bols`} className={location.pathname === '/bols/bols' ? 'italic' : ''} 
                  onClick={closeMenu}>
                    bols
                  </Link>
                </li>
                <li>
                  <Link to={`/bols/bolschawans`} className={location.pathname === '/bols/bolschawans' ? 'italic' : ''} 
                  onClick={closeMenu}>
                    chawans
                  </Link>
                </li>
              </ul>
            )}
          </li>

            <li>
              <a
                className={`menu_title ${location.pathname.includes('/ecriture') ? 'active italic' : ''}`}
                onClick={() => handleMenuClick('ecriture')}
              >
                écritures
              </a>
              {activeMenu === 'ecriture' && (
                <ul className="sub-menu">
                  {subMenuItems.ecriture.map((subItem) => (
                    <li key={subItem.slug}>
                      <Link
                        to={`/ecriture/${subItem.slug}`}
                        className={location.pathname === `/ecriture/${subItem.slug}` ? 'italic' : ''}
                        onClick={closeMenu}
                      >
                        {subItem.title}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link
                      to={`/ecriture/oeuvres`}
                      className={location.pathname === '/ecriture/oeuvres' ? 'italic' : ''}
                      onClick={closeMenu}
                    >
                      oeuvres
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <a
                className={`menu_title ${location.pathname.includes('/transmettre') ? 'active italic' : ''}`}
                onClick={() => handleMenuClick('transmettre')}
              >
                transmettre
              </a>
              {activeMenu === 'transmettre' && (
                <ul className="sub-menu">
                  {subMenuItems.transmettre.map((subItem) => (
                    <li key={subItem.slug}>
                      <Link
                        to={`/transmettre/${subItem.slug}`}
                        className={location.pathname === `/transmettre/${subItem.slug}` ? 'italic' : ''}
                        onClick={closeMenu}
                      >
                        {subItem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
          <ul>
            <li className="menu_title_block">
              <a
                onClick={() => switchLanguage('fr')}
                className={`menu_title ${language === 'fr' ? 'active italic' : ''}`}
              >
                FR
              </a>
              <a
                onClick={() => switchLanguage('en')}
                className={`menu_title ${language === 'en' ? 'active italic' : ''}`}
              >
                EN
              </a>
            </li>
            <li>
              <a className="menu_title" href="mailto:">contact</a>
            </li>
            <li>
              <a className="menu_title" href="https://www.instagram.com/clairelandaissauvage/" target="_blank" rel="noopener noreferrer">instagram</a>
            </li>
          </ul>
      </nav>
      </>
  );
};

export default SidebarMenu;