import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import client from '../../sanity/client';
import './SubPage.scss'

const SubPageChawans = () => {
  const [chawans, setChawans] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "subPageChawan"]{
        title,
        "imageUrl": image.asset->url,
        slug
      }`;

      const result = await client.fetch(query);
      setChawans(result);
    };

    fetchData();
  }, []);

  if (!chawans.length) return <div>Loading...</div>;

  return (
    <div className="sub-page">
      <div className="gallery">
        {chawans.map((chawan) => (
          <div key={chawan.slug.current} className="item">
            <Link to={`/bols/bolschawans/${chawan.slug.current}`}>
              <img src={chawan.imageUrl} alt={chawan.title} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubPageChawans;