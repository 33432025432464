// src/sanity/client.js
import { createClient } from '@sanity/client';

const client = createClient({
  projectId: '8bextc1x',  // Remplace avec ton ID de projet
  dataset: 'sauvage',         // Le nom du dataset que tu utilises (par défaut "production")
  useCdn: true,                  // Utilise le CDN pour des requêtes plus rapides
  apiVersion: '2023-08-20',      // Utilise la version actuelle de l'API
});

export default client;