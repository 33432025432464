// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import ApprochePage from './pages/Approche/ApprochePage';
// import SubPageApproche from './pages/Approche/SubPage'; // Exemple de sous-page
// import BolsPage from './pages/Bols/BolsPage';
// import SubPageBols from './pages/Bols/SubPageBols'; // Exemple de sous-page
// import EcriturePage from './pages/Ecriture/EcriturePage';
// import SubPageEcriture from './pages/Ecriture/SubPage'; // Exemple de sous-page
// import TransmettrePage from './pages/Transmettre/TransmettrePage';
// import SubPageTransmettre from './pages/Transmettre/SubPage'; // Exemple de sous-page
// import OeuvrePage from './pages/Ecriture/OeuvrePage'
// import Home from './pages/Home/Home';
// import IntroPage from './pages/IntroPage/IntroPage'
// import './styles/app.scss';
// import SidebarMenu from './components/SidebarMenu';
// import Rencontres from './pages/Approche/Rencontres';
// import BolDetailsPage from './pages/Bols/BolDetailsPage';
// import SubPageChawans from './pages/Bols/SubPageChawans';
// import { LanguageProvider } from './components/LanguageContext'; // Importer le LanguageProvider

// function App() {
//   const [introFinished, setIntroFinished] = useState(false);

//   const handleIntroAnimationEnd = () => {
//     setIntroFinished(true);
//   };

//   return (
//     <LanguageProvider> {/* Utiliser le LanguageProvider ici */}
//       <div className="app">
//         {!introFinished && <IntroPage onAnimationEnd={handleIntroAnimationEnd} />}
//         <Router>
//           <SidebarMenu />
          // <div className="right_content">
//             <Routes>
//               <Route path="/" element={<Home />} />
//               <Route path="/approche" element={<ApprochePage />} />
//               <Route path="/approche/:slug" element={<SubPageApproche />} />
//               <Route path="/approche/rencontres" element={<Rencontres />} />
//               <Route path="/bols" element={<BolsPage />} />
//               <Route path="/bols/bols" element={<SubPageBols />} />
//               <Route path="/bols/bolschawans" element={<SubPageChawans />} />
//               <Route path="/bols/bols/:slug" element={<BolDetailsPage />} />
//               <Route path="/bols/bolschawans/:slug" element={<BolDetailsPage />} />
//               <Route path="/ecriture" element={<EcriturePage />} />
//               <Route path="/ecriture/oeuvres" element={<OeuvrePage />} />
//               <Route path="/ecriture/:slug" element={<SubPageEcriture />} />
//               <Route path="/transmettre" element={<TransmettrePage />} />
//               <Route path="/transmettre/:slug" element={<SubPageTransmettre />} />
//             </Routes>  
//           </div>
//         </Router>
//       </div>
//     </LanguageProvider>
//   );
// }

// export default App;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import ApprochePage from './pages/Approche/ApprochePage';
import SubPageApproche from './pages/Approche/SubPage';
import BolsPage from './pages/Bols/BolsPage';
import SubPageBols from './pages/Bols/SubPageBols';
import EcriturePage from './pages/Ecriture/EcriturePage';
import SubPageEcriture from './pages/Ecriture/SubPage';
import TransmettrePage from './pages/Transmettre/TransmettrePage';
import SubPageTransmettre from './pages/Transmettre/SubPage';
import OeuvrePage from './pages/Ecriture/OeuvrePage';
import Home from './pages/Home/Home';
import IntroPage from './pages/IntroPage/IntroPage';
import './styles/app.scss';
import SidebarMenu from './components/SidebarMenu';
import Rencontres from './pages/Approche/Rencontres';
import BolDetailsPage from './pages/Bols/BolDetailsPage';
import SubPageChawans from './pages/Bols/SubPageChawans';
import { LanguageProvider } from './components/LanguageContext';
import PageTransition from './components/PageTransition';

function App() {
  const [introFinished, setIntroFinished] = useState(false);

  const handleIntroAnimationEnd = () => {
    setIntroFinished(true);
  };

  return (
    <LanguageProvider>
      <div className="app">
        {!introFinished && <IntroPage onAnimationEnd={handleIntroAnimationEnd} />}
        <Router>
          <SidebarMenu />
          <div className="right_content">
          <PageTransition>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/approche" element={<ApprochePage />} />
              <Route path="/approche/:slug" element={<SubPageApproche />} />
              <Route path="/approche/rencontres" element={<Rencontres />} />
              <Route path="/bols" element={<BolsPage />} />
              <Route path="/bols/bols" element={<SubPageBols />} />
              <Route path="/bols/bolschawans" element={<SubPageChawans />} />
              <Route path="/bols/bols/:slug" element={<BolDetailsPage />} />
              <Route path="/bols/bolschawans/:slug" element={<BolDetailsPage />} />
              <Route path="/ecriture" element={<EcriturePage />} />
              <Route path="/ecriture/oeuvres" element={<OeuvrePage />} />
              <Route path="/ecriture/:slug" element={<SubPageEcriture />} />
              <Route path="/transmettre" element={<TransmettrePage />} />
              <Route path="/transmettre/:slug" element={<SubPageTransmettre />} />
            </Routes>
          </PageTransition>
          </div>
        </Router>
      </div>
    </LanguageProvider>
  );
}

export default App;