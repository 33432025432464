import React from 'react';
import './HamburgerButton.scss';

const HamburgerButton = ({ isOpen, toggleMenu }) => {
  return (
    <button className="hamburger-button" onClick={toggleMenu} aria-label="Toggle Menu">
      <svg width="40" height="40" viewBox="20 20 100 100" className={isOpen ? 'open' : ''}>
  <line className="line top" x1="20" y1="30" x2="80" y2="30" />
  <line className="line middle" x1="20" y1="50" x2="80" y2="50" />
  <line className="line bottom" x1="20" y1="70" x2="80" y2="70" />
</svg>
    </button>
  );
};

export default HamburgerButton;