// import React, { useEffect, useState, useRef } from 'react';
// import client from '../../sanity/client'; // Assure-toi que le chemin est correct pour ton projet
// import '../Transmettre/TransmettrePage.scss';
// import { ArrowLeft, ArrowRight } from '../../assets/svg'; // Tes icônes pour les flèches

// const TransmettrePage = () => {
//   const [data, setData] = useState(null);
//   const [currentIndex, setCurrentIndex] = useState(0); // Pour garder la trace de l'image actuelle
//   const intervalRef = useRef(null); // Pour stocker l'intervalle, on utilise useRef

//   useEffect(() => {
//     // Requête pour récupérer les données de la page Approche
//     const fetchData = async () => {
//       const query = `*[_type == "transmettre"][0]{
//         title,
//         gallery[]{ "imageUrl": asset->url }
//       }`;
      
//       const result = await client.fetch(query);
//       setData(result);
//       console.log(data)
//     };

//     fetchData();
//   }, []);

//   // Fonction pour changer automatiquement d'image
//   const startAutoSlide = () => {
//     intervalRef.current = setInterval(() => {
//       if (data?.gallery?.length) {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % data.gallery.length);
//       }
//     }, 7000);
//   };

//   // Fonction pour arrêter le changement automatique
//   const stopAutoSlide = () => {
//     clearInterval(intervalRef.current);
//   };

//   // Réinitialiser le changement automatique à chaque fois que currentIndex change (soit automatiquement, soit manuellement)
//   useEffect(() => {
//     stopAutoSlide();
//     startAutoSlide();
//     return () => stopAutoSlide(); // Nettoyage de l'intervalle quand le composant est démonté
//   }, [currentIndex, data]);

//   // Fonction pour changer l'image manuellement
//   const goToNextSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % data.gallery.length);
//   };

//   const goToPrevSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex - 1 + data.gallery.length) % data.gallery.length);
//   };

//   if (!data) return <div>Loading...</div>;

//   return (
//     <div className="approche-page">
//       <h1 className="approche-text">{data.title}</h1>

//       {data.gallery?.length > 0 && (
//         <div className="carousel">
//           {/* Affichage de toutes les images, seule celle active aura l'opacité 1 */}
//           <div className="carousel-image-container">
//             {data.gallery.map((image, index) => (
//               <img
//                 key={index}
//                 src={image.imageUrl}
//                 alt={`Slide ${index + 1}`}
//                 className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
//               />
//             ))}
//           </div>

//           {/* Flèches de navigation */}
//           <div className="carousel-controls">
//             <button className="carousel-control prev" onClick={goToPrevSlide}>
//               <ArrowLeft />
//             </button>
//             <button className="carousel-control next" onClick={goToNextSlide}>
//               <ArrowRight />
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TransmettrePage;

import React, { useEffect, useState, useRef } from 'react';
import client from '../../sanity/client';
import '../Approche/ApprochePage.scss';
import { ArrowLeft, ArrowRight } from '../../assets/svg';

const TransmettrePage = () => {
  const [data, setData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "transmettre"][0]{
        title,
        gallery[]{ "imageUrl": asset->url }
      }`;

      const result = await client.fetch(query);
      console.log(result); // Vérifiez le contenu de result ici
      setData(result);
    };

    fetchData();
  }, []);

  const startAutoSlide = () => {
    intervalRef.current = setInterval(() => {
      if (data?.gallery?.length) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.gallery.length);
      }
    }, 7000);
  };

  const stopAutoSlide = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    if (data?.gallery?.length) {
      stopAutoSlide();
      startAutoSlide();
    }
    return () => stopAutoSlide();
  }, [currentIndex, data]);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.gallery.length);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.gallery.length) % data.gallery.length);
  };

  if (!data) return <div>Loading...</div>;

  return (
    <div className="approche-page">
      <h1 className="approche-text">{data.title}</h1>
      {data?.gallery && data.gallery.length > 0 && (
        <div className="carousel">
          <div className="carousel-image-container">
            {data.gallery.map((image, index) => (
              <img
                key={index}
                src={image.imageUrl}
                alt={`Slide ${index + 1}`}
                className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
              />
            ))}
          </div>
          <div className="carousel-controls">
            <button className="carousel-control prev" onClick={goToPrevSlide}>
              <ArrowLeft />
            </button>
            <button className="carousel-control next" onClick={goToNextSlide}>
              <ArrowRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransmettrePage;