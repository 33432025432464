import React from 'react';

const Home = () => {
  return (
        <div>
            HOME
        </div>    
  );
};

export default Home;