import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from '../../sanity/client';

const SubPageApproche = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "subPageApproche" && slug.current == $slug][0]{
        title,
        "imageUrl": image.asset->url,
        content
      }`;
      const params = { slug };
      const result = await client.fetch(query, params);
      setData(result);
    };

    fetchData();
  }, [slug]);

  if (!data) return <div>Loading...</div>;

  return (
    <div className="sub-page-approche">
      <h1>{data.title}</h1>
      {data.imageUrl && (
        <img src={data.imageUrl} alt={data.title} />
      )}
      {/* Si le contenu est un tableau, on peut mapper les éléments */}
      {data.content && data.content.map((block) => {
        if (block._type === 'block') {
          return <p key={block._key}>{block.children[0].text}</p>;
        } else if (block._type === 'image') {
          return <img key={block._key} src={block.imageUrl} alt={block.alt || 'Image'} />;
        }
        return null;
      })}
    </div>
  );
};

export default SubPageApproche;