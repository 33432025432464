import React, { useState, useEffect } from 'react';
import client from '../../sanity/client';
import './OeuvrePage.scss';
import { PlusIcon, MinusIcon } from '../../assets/svg'; // Import des icônes
import headerImg from '../../assets/images/photoIntro.jpg';
import { useLanguage } from '../../components/LanguageContext'; // Importer le contexte


const OeuvrePage = () => {
  const [data, setData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const { language } = useLanguage(); // Récupérer la langue actuelle

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "oeuvres"][0]{
        title,
        works[]{
          title,
          description,
          images[]{ "imageUrl": asset->url }
        }
      }`;
      
      const result = await client.fetch(query);
      setData(result);
    };

    fetchData();
  }, []);

  const toggleDrawer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  if (!data) return <div>Loading...</div>;

  return (
    <div className="oeuvre-page">
      <h1>{data.title[language]}</h1> {/* Affichage du titre en fonction de la langue */}
      <div className="header_img">
        <img src={headerImg} alt="" />
      </div>
      <div className="works-list">
        {data.works && data.works.map((work, index) => (
          <div key={index} className={`work-item ${activeIndex === index ? 'active' : ''}`}>
            <div className="work-header" onClick={() => toggleDrawer(index)}>
              <h2>{work.title[language]}</h2>
              {/* Utilisation des icônes SVG */}
              {activeIndex === index ? <MinusIcon /> : <PlusIcon />}
            </div>
            <div className="work-content-wrapper">
              <div className="work-content">
                <p className="work-content-text">
                 {work.description[language] ? work.description[language].split('\n').map((line, idx) => (
                  <span className="work-content-text-split" key={idx}>
                    {line}
                    <br />
                  </span>
                )) : null}
                </p>
                <div className="images-grid">
                  {work.images && work.images.map((image, imgIndex) => (
                    <img 
                      key={imgIndex} 
                      src={image.imageUrl} 
                      alt={`work-${index}`} 
                      className={`image-item image-item-${imgIndex}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OeuvrePage;

// OeuvrePage.js


// import React, { useState, useEffect } from 'react';
// import client from '../../sanity/client';
// import { useLanguage } from '../../components/LanguageContext'; // Importer le contexte
// import './OeuvrePage.scss';

// const OeuvrePage = () => {
//   const [data, setData] = useState(null);
//   const { language } = useLanguage(); // Récupérer la langue actuelle

//   useEffect(() => {
//     const fetchData = async () => {
//       const query = `*[_type == "oeuvres"][0]{
//         title,
//         "works": works[]{
//           title,
//           description,
//           images[]{ "imageUrl": asset->url }
//         }
//       }`;
      
//       const result = await client.fetch(query);
//       setData(result);
//     };

//     fetchData();
//   }, []);

//   if (!data) return <div>Loading...</div>;

//   return (
//     <div className="oeuvre-page">
//       <h1>{data.title[language]}</h1> {/* Affichage du titre en fonction de la langue */}
//       <div className="works-list">
//         {data.works && data.works.map((work, index) => (
//           <div key={index} className="work-item">
//             <h2>{work.title[language]}</h2> {/* Affichage du titre de l'œuvre en fonction de la langue */}
//             <p>{work.description[language]}</p> {/* Affichage de la description en fonction de la langue */}
//             <div className="images-grid">
//               {work.images && work.images.map((image, imgIndex) => (
//                 <img key={imgIndex} src={image.imageUrl} alt={`work-${index}`} />
//               ))}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default OeuvrePage;