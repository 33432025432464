import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from '../../sanity/client';
import './SubPageTransmettre.scss';
import { useLanguage } from '../../components/LanguageContext';

const SubPageTransmettre = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const { language } = useLanguage();
  const getText = (textObj) => textObj && textObj[language] ? textObj[language] : "Texte non disponible";

  // Dans le JSX
  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "subPageTransmettre" && slug.current == $slug][0]{
        title,
        mainText,
        secondaryText,
        "mainImageUrl": mainImage.asset->url,
        gallery[] {
          "imageUrl": asset->url
        }
      }`;

      const result = await client.fetch(query, { slug });
      setData(result);
    };

    fetchData();
  }, [slug]);

  if (!data) return <div>Loading...</div>;

  return (
    <div className="subpage-transmettre">
      <div className="content-container">
        <div className="image-content">
          <img
            src={data.mainImageUrl}
            alt={data.title || "Main Image"}
            className="main-image"
          />
          {data.gallery && data.gallery.map((image, index) => (
            <img
              key={index}
              src={image.imageUrl}
              alt={`Gallery Image ${index}`}
              className="gallery-image"
            />
          ))}
        </div>
        <div className="text-content sticky">
          <h1>{data.title}</h1>
          <div className="text-content-1">
            {data.mainText[language]
              ? data.mainText[language].split('\n').map((line, idx) => (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                ))
              : null}
          </div>

          <div className="text-content-1">
            {data.secondaryText[language]
              ? data.secondaryText[language].split('\n').map((line, idx) => (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubPageTransmettre;