// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import client from '../../sanity/client';
// import '../Bols/BolDetailsPage.scss';
// import { ArrowLeft, ArrowRight, GridIcon } from '../../assets/svg';

// const BolDetailsPage = () => {
//   const { slug } = useParams();
//   const [bolDetails, setBolDetails] = useState(null);
//   const [allSlugs, setAllSlugs] = useState([]); // Pour stocker tous les slugs disponibles
//   const [currentImage, setCurrentImage] = useState(null);
//   const [isFading, setIsFading] = useState(false); // État pour gérer l'animation de l'image
//   const navigate = useNavigate();

//   // Récupère les détails du bol/chawan en fonction du slug
//   useEffect(() => {
//     const fetchBolDetails = async () => {
//       const query = `*[_type in ["subPageBols", "subPageChawan"] && slug.current == $slug][0]{
//         title,
//         description,
//         "imageUrl": image.asset->url,
//         gallery[]{ "imageUrl": asset->url }
//       }`;

//       const result = await client.fetch(query, { slug });

//       if (result) {
//         setBolDetails(result);
//         setCurrentImage(result?.imageUrl); // Définit l'image principale
//       }
//     };

//     fetchBolDetails();
//   }, [slug]);

//   // Récupère tous les slugs des bols et des chawans pour la navigation
//   useEffect(() => {
//     const fetchAllSlugs = async () => {
//       const query = `*[_type in ["subPageBols", "subPageChawan"]]{
//         "slug": slug.current
//       }`;

//       const result = await client.fetch(query);
//       setAllSlugs(result.map(item => item.slug)); // Stocke tous les slugs dans un tableau
//     };

//     fetchAllSlugs();
//   }, []);

//   const handleImageChange = (newImage) => {
//     setIsFading(true);
//     setTimeout(() => {
//       setCurrentImage(newImage);
//       setIsFading(false);
//     }, 300);
//   };

//   if (!bolDetails) return <div>Loading...</div>;

//   // Fusion de l'image principale avec les autres images. Vérification que "gallery" n'est pas null ou undefined
//   const allImages = bolDetails.gallery ? [bolDetails.imageUrl, ...bolDetails.gallery.map(image => image.imageUrl)] : [bolDetails.imageUrl];

//   // Logique pour trouver le slug précédent et suivant
//   const currentIndex = allSlugs.indexOf(slug);
//   const previousSlug = allSlugs[currentIndex - 1] || allSlugs[allSlugs.length - 1]; // Prend le dernier si on est au début
//   const nextSlug = allSlugs[currentIndex + 1] || allSlugs[0]; // Prend le premier si on est à la fin

//   return (
//     <div className="bol-details-page">
//       <div className="main-image-container">
//         <img
//           src={currentImage}
//           alt={bolDetails.title}
//           className={`main-image ${isFading ? 'fade' : ''}`}
//         />
//       </div>

//       <div className="gallery">
//         {allImages.map((image, index) => (
//           <img
//             key={index}
//             src={image}
//             alt={`${bolDetails.title} thumbnail ${index}`}
//             onClick={() => handleImageChange(image)}
//             className={currentImage === image ? 'active' : ''}
//           />
//         ))}
//       </div>

//       <div className="description-container">
//         <p>{bolDetails.description}</p>
//       </div>

//       <div className="navigation-controls">
//         <button onClick={() => navigate(`/bols/${previousSlug}`)}>
//           <ArrowLeft />
//         </button>
//         <button onClick={() => navigate('/bols/bols')}>
//           <GridIcon />
//         </button>
//         <button onClick={() => navigate(`/bols/${nextSlug}`)}>
//           <ArrowRight />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default BolDetailsPage;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import client from '../../sanity/client';
import '../Bols/BolDetailsPage.scss';
import { ArrowLeft, ArrowRight, GridIcon } from '../../assets/svg';
import { useLanguage } from '../../components/LanguageContext'; // Importer le contexte

const BolDetailsPage = () => {
  const { slug } = useParams();
  const [bolDetails, setBolDetails] = useState(null);
  const [allSlugs, setAllSlugs] = useState([]); // Pour stocker tous les slugs disponibles de la sous-page en cours
  const [currentImage, setCurrentImage] = useState(null);
  const [isFading, setIsFading] = useState(false); // État pour gérer l'animation de l'image
  const navigate = useNavigate();
  const { language } = useLanguage(); // Récupérer la langue actuelle

  // Détecter si l'on est dans "bols" ou "bolschawans" selon l'URL actuelle
  const isChawanPage = slug.includes('chawan');
  const basePath = isChawanPage ? 'bolschawans' : 'bols';

  // Récupère les détails du bol/chawan en fonction du slug
  useEffect(() => {
    const fetchBolDetails = async () => {
      const query = `*[_type in ["subPageBols", "subPageChawan"] && slug.current == $slug][0]{
        title,
        description,
        "imageUrl": image.asset->url,
        gallery[]{ "imageUrl": asset->url }
      }`;

      const result = await client.fetch(query, { slug });

      if (result) {
        setBolDetails(result);
        setCurrentImage(result?.imageUrl); // Définit l'image principale
      }
    };

    fetchBolDetails();
  }, [slug]);

  // Récupère tous les slugs de la sous-page correspondante (soit "subPageBols" soit "subPageChawan")
  useEffect(() => {
    const fetchAllSlugs = async () => {
      const query = `*[_type == "${isChawanPage ? 'subPageChawan' : 'subPageBols'}"]{
        "slug": slug.current
      }`;

      const result = await client.fetch(query);
      setAllSlugs(result.map(item => item.slug)); // Stocke tous les slugs de la sous-page en cours
    };

    fetchAllSlugs();
  }, [isChawanPage]);

  const handleImageChange = (newImage) => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentImage(newImage);
      setIsFading(false);
    }, 300);
  };

  if (!bolDetails) return <div>Loading...</div>;

  // Fusion de l'image principale avec les autres images. Vérification que "gallery" n'est pas null ou undefined
  const allImages = bolDetails.gallery ? [bolDetails.imageUrl, ...bolDetails.gallery.map(image => image.imageUrl)] : [bolDetails.imageUrl];

  // Logique pour trouver le slug précédent et suivant dans la sous-page actuelle (bols ou bolschawans)
  const currentIndex = allSlugs.indexOf(slug);
  const previousSlug = allSlugs[currentIndex - 1] || allSlugs[allSlugs.length - 1]; // Prend le dernier si on est au début
  const nextSlug = allSlugs[currentIndex + 1] || allSlugs[0]; // Prend le premier si on est à la fin

  return (
    <>
      <div className="bol-details-page">
        <div className="main-image-container">
          <img
            src={currentImage}
            alt={bolDetails.title}
            className={`main-image ${isFading ? 'fade' : ''}`}
          />
        </div>
        <div className="bol-details-rightside">
          <div className="gallery">
            {allImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${bolDetails.title} thumbnail ${index}`}
                onClick={() => handleImageChange(image)}
                className={currentImage === image ? 'active' : ''}
              />
            ))}
          </div>
          <div className="description-container">
            {/* <p>{bolDetails.description}</p> */}
            <p className="work-content-text">
              {bolDetails.description[language] ? bolDetails.description[language].split('\n').map((line, idx) => (
              <span className="work-content-text-split" key={idx}>
                {line}
                <br />
              </span>
              )) : null}
            </p>
          </div>
        </div>
      </div>
      <div className="navigation-controls">
        {/* Bouton flèche gauche pour passer à l'élément précédent */}
        <button onClick={() => navigate(`/bols/${basePath}/${previousSlug}`)}>
          <ArrowLeft />
        </button>

        {/* Bouton pour revenir à la page subPage parent */}
        <button onClick={() => navigate(`/bols/${basePath}`)}>
          <GridIcon />
        </button>

        {/* Bouton flèche droite pour passer à l'élément suivant */}
        <button onClick={() => navigate(`/bols/${basePath}/${nextSlug}`)}>
          <ArrowRight />
        </button>
      </div>
    </>
  );
};

export default BolDetailsPage;